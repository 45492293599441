import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// import images
import Stage1Thumb from "../images/stage1-thumb.jpg";
import Stage2Thumb from "../images/stage2-thumb.jpg";
import Stage3Thumb from "../images/stage3-thumb.jpg";

export default () => {
  return (
    <Layout>
        <section className="videos">
            <div className="container">
              <h1 className="text-center">Choose which video you'd like to watch&hellip;</h1>
              <div className="grid-row-3">
                <div className="grid-item video-thumb">
                  <Link to="/videos/video-1" className="thumb-holder">
                    <img src={Stage1Thumb} alt="" />
                  </Link>
                  <h2>
                    <Link to="/videos/video-1">Basic Orion Process</Link>
                  </h2>
                </div>
                <div className="grid-item video-thumb">
                  <Link to="/videos/video-2" className="thumb-holder">
                    <img src={Stage2Thumb} alt="" />
                  </Link>
                  <h2>
                    <Link to="/videos/video-2">Base Orion Unit Plus Options</Link>
                  </h2>
                </div>
                <div className="grid-item video-thumb">
                  <Link to="/videos/video-3" className="thumb-holder">
                    <img src={Stage3Thumb} alt="" />
                  </Link>
                  <h2>
                    <Link to="/videos/video-3">Base Orion Unit Plus Options With Recovery RO</Link>
                  </h2>
                </div>
              </div>
            </div>
        </section>
    </Layout>
  )
}
